"use client";

import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import NavLink from "../../common/NavLink";
import Image from "gatsby-plugin-sanity-image";

const MobileNavbar = ({ data: { menu, logo } }) => {
  const [sidebar, setSidebar] = useState(false);
  const [activeSubnav, setActiveSubnav] = useState(null);

  function handleSidebar() {
    setSidebar(!sidebar);
  }

  return (
    <div className="lg:hidden fixed top-0 left-0 w-full bg-[#323232] h-14 px-2 sm:px-5 flex items-center z-[11]">
      <button
        title="Open Sidebar"
        className="text-secondary"
        onClick={handleSidebar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 sm:w-10 h-8 sm:h-10"
          fill="currentColor"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <g fill="currentColor" stroke="currentColor">
            <path
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 17h14M5 12h14M5 7h14"
            ></path>
          </g>
        </svg>
      </button>
      <div className="fixed top-0 left-1/2 -translate-x-1/2">
        <Link to="/" className="pt-3">
          <Image
            asset={logo.asset}
            crop={logo.crop}
            hotspot={logo.hotspot}
            alt={logo?.alt}
            className="w-full h-14 object-contain"
          />
          {/* <GatsbyImage
            image={logo?.asset?.gatsbyImageData}
            objectFit="contain"
            className="h-[40px]"
          /> */}
        </Link>
      </div>

      <div
        className={`fixed inset-0 w-full h-full transition-all duration-1000 ease-linear ${
          sidebar ? "block bg-black/70" : "hidden bg-black/10"
        }`}
        onClick={handleSidebar}
      />

      <div
        className={`fixed w-[270px] top-0 bg-black h-full transition-all duration-200 ease-linear ${
          sidebar ? "left-0" : "-left-72"
        }`}
      >
        <div className="relative w-full pt-10">
          <button
            title="Close Sidebar"
            className="text-white absolute top-3 right-3"
            onClick={() => setSidebar(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <ul>
            {menu.map((item, index) => (
              <li key={index}>
                {item?.childMenu.length > 0 && activeSubnav !== index ? (
                  <button
                    className="px-5 py-[10px] text-white hover:text-secondary capitalize block text-sm"
                    onClick={() => setActiveSubnav(index)}
                  >
                    {item?.link?.label}
                  </button>
                ) : (
                  // <Link
                  //   to={item.link.startsWith("/") ? item.link : `/${item.link}`}
                  //   className={`px-5 py-[10px] capitalize block text-sm ${
                  //     activeSubnav === index
                  //       ? "text-black bg-secondary"
                  //       : "text-white bg-transparent"
                  //   }`}
                  // >
                  //   {item.title}
                  // </Link>
                  <NavLink
                    linkType={item?.link?.type}
                    label={item?.link?.label}
                    path={item?.link?.link}
                    cls={`px-5 py-[10px] capitalize block text-sm ${
                      activeSubnav === index
                        ? "text-black bg-secondary"
                        : "text-white bg-transparent"
                    }`}
                  />
                )}

                <ul
                  className={`pl-5 transition-all duration-200 ease-linear overflow-y-scroll ${
                    activeSubnav === index ? "h-20" : "h-0"
                  }`}
                >
                  {item?.childMenu.length > 0 &&
                    item?.childMenu.map((subnav, key) => (
                      <li key={key}>
                        {/* <Link
                          to={
                            subnav.link.startsWith("/")
                              ? subnav.link
                              : `/${subnav.link}`
                          }
                          className="px-5 py-[10px] text-white hover:text-secondary capitalize block text-sm"
                        >
                          {subnav.title}
                        </Link> */}
                        <NavLink
                          linkType={subnav?.type}
                          label={subnav?.label}
                          path={subnav?.link}
                          cls={`px-5 py-[10px] text-white hover:text-secondary capitalize block text-sm`}
                        />
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
