import React from "react";
import PortableContent from "../../common/PortableContent";

const Footer = ({ data }) => {
  return (
    <footer className="relative pt-[60px] pb-2 bg-[#292929] mx-auto text-center text-xs leading-6 uppercase">
      <div className="absolute -top-3 left-1/2 -translate-x-1/2 text-secondary w-10 h-10 bg-[#292929] shadow-lg text-center flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
          />
        </svg>
      </div>
      <div className="custom_container">
        <div className="my-2">
          <p className="text-xs leading-[22px] text-[#a6a6a6]">
            {data?._rawCc && <PortableContent data={data?._rawCc} />}
            {/* © 2023 wjrapp All Rights Reserved. build by{" "}
            <a
              className="text-secondary"
              href="https://www.civsav.com/"
              target="_blank"
              rel="nofollow"
            >
              Civsav
            </a>{" "} */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
