import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import NavLink from "../../common/NavLink";
import Image from "gatsby-plugin-sanity-image";

const DesktopNavbar = ({ data: { menu, logo } }) => {
  const [rightMenu, setRightMenu] = useState([]);
  const [leftMenu, setLeftMenu] = useState([]);

  useEffect(() => {
    if (menu?.length > 3) {
      setLeftMenu(menu.slice(0, 3));
      setRightMenu(menu.slice(3));
    } else {
      setLeftMenu(menu.slice(0));
      setRightMenu([]);
    }
  }, []);

  return (
    <div className="hidden lg:block relative top-0 left-0 pb-[10px] w-full z-10 bg-black">
      <div className="custom_container">
        <div className="w-full grid grid-cols-3">
          {/* 1st */}
          <div className="mx-[10px] xl:mx-6 my-2 flex items-center justify-center">
            <ul className="mt-[9px] ml-[30px] list-none text-[13px] leading-[18px] font-semibold tracking-tight">
              {leftMenu.map((item, index) => (
                <li className="mx-[5px] float-left relative group" key={index}>
                  <NavLink
                    linkType={item?.link?.type}
                    label={item?.link?.label}
                    path={item?.link?.link}
                    cls="pl-5 mt-[9px] text-white hover:text-secondary uppercase"
                  />

                  {item?.childMenu?.length > 0 && (
                    <div className="hidden group-hover:block px-5 py-5 w-[230px] bg-black absolute left-0 z-20">
                      {item.childMenu.map((subnavlink, key) => (
                        <NavLink
                          linkType={subnavlink?.type}
                          label={subnavlink?.label}
                          path={subnavlink?.link}
                          key={key}
                          cls="block mt-[9px] text-white hover:text-secondary text-[13px]"
                        />
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* 2nd */}
          <div className="flex items-center justify-center">
            <Link to="/" className="pt-3">
              <Image
                asset={logo.asset}
                crop={logo.crop}
                hotspot={logo.hotspot}
                alt={logo?.alt}
                className="w-full h-[70px] object-contain"
              />
              {/* <GatsbyImage
                image={logo?.asset?.gatsbyImageData}
                alt={logo?.alt}
                height={70}
                objectFit="contain"
              /> */}
              {/* <StaticImage
                src="https://wjrapp.com/wp-content/uploads/2018/05/wjrapp-logo.png"
                alt="Logo"
                loading="eager"
                height={70}
                objectFit="contain"
              /> */}
            </Link>
          </div>
          {/* 3rd */}
          <div className="mx-[10px] xl:mx-6 my-2 flex items-center">
            <ul className="mx-auto mt-[9px] ml-[30px] list-none text-[13px] leading-[18px] font-semibold tracking-tight">
              {rightMenu.map((item, index) => (
                <li className="mx-[5px] float-left relative group" key={index}>
                  <NavLink
                    linkType={item?.link?.type}
                    label={item?.link?.label}
                    path={item?.link?.link}
                    cls="pl-5 mt-[9px] text-white hover:text-secondary uppercase"
                  />

                  {item?.childMenu.length > 0 && (
                    <div className="hidden group-hover:block px-5 py-5 w-[230px] bg-black absolute left-0 z-20">
                      {item.childMenu.map((subnavlink, key) => (
                        <NavLink
                          linkType={subnavlink?.type}
                          label={subnavlink?.label}
                          path={subnavlink?.link}
                          key={key}
                          cls="block mt-[9px] text-white hover:text-secondary text-[13px]"
                        />
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNavbar;
