import React, { Fragment } from "react";
import { PortableText } from "@portabletext/react";

export default function PortableContent({
  data,
  linkColor = "text-secondary",
}) {
  return (
    <Fragment>
      {data && (
        <PortableText
          value={data}
          components={{
            marks: {
              color: ({ value, children }) => (
                <span style={{ color: value.hex }}>{children}</span>
              ),
              link: ({ value, children }) => {
                const target = (value?.href || "").startsWith("http")
                  ? "_blank"
                  : undefined;
                return (
                  <a
                    href={value?.href}
                    target={target}
                    rel={target === "_blank" && "noindex nofollow"}
                    className={`transition-colors duration-150 ease-linear ${linkColor}`}
                  >
                    {children}
                  </a>
                );
              },
            },
          }}
        />
      )}
    </Fragment>
  );
}
