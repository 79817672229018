import React from "react";
import { Link } from "gatsby";

export default function NavLink({ linkType, path = "", label = "", cls = "" }) {
  if (linkType === "label")
    return <span className={`cursor-pointer ${cls} `}>{label}</span>;
  if (linkType === "external") {
    return (
      <a
        href={path}
        className={`${cls}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {label}
      </a>
    );
  } else {
    return (
      <Link to={path} className={`${cls}`}>
        {label}
      </Link>
    );
  }
}
