import React from "react";
import DesktopNavbar from "./Desktop";
import MobileNavbar from "./Mobile";

const Navbar = ({ data }) => {
  return (
    <div className="w-full">
      <DesktopNavbar data={data} />
      <MobileNavbar data={data} />
    </div>
  );
};

export default Navbar;
