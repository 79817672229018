import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Topbar from "./topbar";
import Navbar from "./navbar";
import Footer from "./footer";

const Layout = ({ children }) => {
  const { sanitySitesettings } = useStaticQuery(graphql`
    query {
      sanitySitesettings {
        header {
          logo {
            alt
            hotspot {
              x
              y
              width
              height
            }
            crop {
              top
              bottom
              left
              right
            }
            asset {
              _id
              metadata {
                blurHash
              }
            }
          }
          menu {
            link {
              label
              link
              type
            }
            childMenu {
              label
              link
              type
            }
          }
        }
        topbar {
          enable
          address1
          address2
          phone
        }
        footer {
          _rawCc
        }
      }
    }
  `);

  return (
    <div className="relative">
      {sanitySitesettings?.topbar && sanitySitesettings?.topbar.enable && (
        <Topbar data={sanitySitesettings?.topbar} />
      )}
      <Navbar data={sanitySitesettings?.header} />
      {children}
      <Footer data={sanitySitesettings?.footer} />
    </div>
  );
};

export default Layout;
